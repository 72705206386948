// Libs
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

// Components
import { device } from 'components/device';

export const Container = styled.section`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 4.375rem 0;
  background: #f4f5f7;

  @media ${device.laptop} {
    flex-direction: column;
    padding: 1.875rem 1.25rem;
  }
`;

export const ContentTitle = styled.div`
  width: 100%;
  max-width: 29.375rem;

  @media ${device.laptop} {
    max-width: 25rem;
    margin-bottom: 1.875rem;
  }
`;

export const Title = styled(ReactMarkdown)`
  h2 {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #231f20;
    text-align: left;
    margin: 0;

    @media ${device.laptop} {
      font-size: 1.125rem;
      line-height: 1.375rem;
    }
  }
`;

export const ContentList = styled.div`
  width: 100%;
  max-width: 35.625rem;
  background: #fff;
  border-radius: 0.5rem;

  @media ${device.laptop} {
    max-width: 100%;
  }
`;

export const List = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 1.875rem;

  & + div {
    border-top: 1px solid #c6c8cc;
  }

  @media ${device.tablet} {
    padding: 1.875rem 1.25rem;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;

    em {
      font-style: normal;
      @media ${device.mobile} {
        width: 7rem;
      }
    }

    a {
      display: flex;
      align-items: center;
      color: #45a7df;

      @media ${device.mobile} {
        width: 10rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      @media ${device.mobile320} {
        width: 7.5rem;
        font-size: 0.625rem;
        line-height: 1rem;
      }

      img {
        margin-right: 1.125rem;

        @media ${device.mobile320} {
          margin-right: 0.875rem;
        }
      }
    }
  }
`;
