// Libs
import React from 'react';

// Styles
import { Container, ContentTitle, Title, ContentList, List } from './style';

function ListGroup({ markdownContent }) {
  const ListData = markdownContent.split(/(##.*\n\n)/i).filter(el => el);
  const ListTitle = ListData[0];
  const ListItems = ListData[1].split('\n\n');

  return (
    <Container>
      <ContentTitle>
        <Title children={ListTitle} />
      </ContentTitle>
      <ContentList>
        {ListItems.map((item, index) => (
          <List key={index} children={item} linkTarget="_blank" />
        ))}
      </ContentList>
    </Container>
  );
}

export default ListGroup;
